import * as React from "react"
import Layout from "../../../components/layout"
import PartnersPage from "../../../components/pageComponents/partners/partners"
import messages from "../../../i18n/es"

const IndexPageDe = ({ location }) => (
  <Layout location={location} messages={messages}>
    <PartnersPage langKey="es" partner="dante" />
  </Layout>
)

export default IndexPageDe
